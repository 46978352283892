





















































import { Component, Vue } from 'vue-property-decorator'
import CardWithTitle from '@/components/Card/CardWithTitle.vue'
import FSelect from '@/components/Form/FSelect.vue'
import FButton from '@/components/Button/FButton.vue';
import { ReportsService } from '@/services/ReportsService'

@Component({
  components: {
    CardWithTitle,
    FSelect,
    FButton
  },
})
export default class ReportsTemplate extends Vue {
  store = ReportsService;

  onSelectInput(){
    this.$router.push(`/reports/${this.store.currentReportId}`)
  }

  get earningsDashboard() {
    return this.$route.fullPath.includes('/earnings-dashboard');
  }

  get isFullScreenSupported() {
    const doc = document.documentElement as any;
    return 'requestFullscreen' in doc
      || 'mozRequestFullScreen' in doc 
      || 'webkitRequestFullscreen' in doc 
      || 'msRequestFullscreen' in doc; 
  }

  get isFullScreen() {
    return this.store.isFullScreen;
  }

  toggleFullScreen() {
    this.store.toggleFullScreen();
  }
}
